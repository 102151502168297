/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import countryList from "./assets/world.json";
import logo from "./assets/Calibrite-colour-nocircle.svg";

import {
  Box,
  Flex,
  Button,
  Text,
  Heading,
  Select,
  Input,
  FormControl,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import { color } from "./constants/color";
import { useTranslation } from "react-i18next";
import Upgrade from "./Upgrade";

const Form = ({ language, setLanguage }) => {
  const [serial, setSerial] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product: "",
      operating: "",
      software: "",
      // purchasedFrom: "",
      // purchaseDate: "",
      // orderRef: "",
      serialNum: "",
      emailAdd: "",
      companyName: "",
      firstName: "",
      lastName: "",
      phoneNum: "",
      country: "",
      support: false,
      terms: false,
    },
  });

  const onSubmit = async (data) => {
    localStorage.setItem("Calibrite-sn", data.serialNum);

    console.table(data);

    const date = new Date();

    //partnbr to change to product
    const postData = {
      PartNbr: partNumber,
      VersionString: data.software,
      RegistrationCode: "",
      SerialNbr: data.serialNum,
      FirstName: data.firstName,
      LastName: data.lastName,
      Salutation: "",
      Title: "",
      Address: "",
      City: "",
      State: "",
      PostalCode: "",
      Modified: "",
      ModifiedBy: "",
      ExportStartDate: "",
      ExportEndDate: "",
      Description: data.product,
      Language: language,
      Company: data.companyName,
      Country: "",
      ISO: data.country,
      Phone: data.phoneNum,
      Fax: "",
      EMail: data.emailAdd,
      Support: data.support,
      PurchaseDate: "",
      LicenceKey: "",
      OS: data.operating,
      OrigSerialNbr: "",
      source: "Calibrite Upgrade",
      lumescaImportDate: date.toISOString(),
    };

    const string = JSON.stringify(postData);
    console.log(string);
    // alert("Test form output: " + string);

    //SDNOTE
    const postUrl =
      "https://profiler-app-reg-api.calibrite.com/xriteRegistration";

    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: string,
    };

    const response = await fetch(postUrl, fetchOptions);

    let dataRes = await response;

    console.log(response);
    console.log(dataRes);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    let base64Sn = window.btoa(data.serialNum);
    base64Sn = base64Sn.replace("=", "");

    reset();

    if (data.country === "us" || data.country === "ca") {
      window.location.replace(
        `https://buy.stripe.com/aEUdTmcuj6bwe0U7su?client_reference_id=${base64Sn}&prefilled_email=${data.emailAdd}&locale=${data.country}`
      );
    } else {
      window.location.replace(
        `https://buy.stripe.com/dR6g0e2SheI07fycMP?client_reference_id=${base64Sn}&prefilled_email=${data.emailAdd}&locale=${data.country}`
      );
    }
  };

  //CHANGE THE HTML FOR ATTRIBUTES SD 13/06
  // Amend validation, maybe move below box, update text relevant to error.

  const { t, i18n } = useTranslation();

  const [partNumber, setPartNumber] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    let sno = params.get("sno");
    let product = params.get("product");
    let partNum = params.get("partnum");
    let os = params.get("os");
    let dist = params.get("dist");
    let lng = params.get("lng");
    let ver = params.get("ver");

    licenceApi(sno);

    setPartNumber(partNum);

    setValue("serialNum", sno || "Not available");
    setValue("product", product || "Not available");
    setValue("operating", dist || "Not available");
    setValue("software", ver || "Not available");
    setValue("country", "gb");

    if (lng !== null) {
      setLanguage(lng);
    } else {
      setLanguage("en");
    }
  }, []);

  const [upgrade, setUpgrade] = useState(false);

  const [terms, setTerms] = useState(false);

  const [licenced, setLicenced] = useState(false);

  const licenceApi = async (sn) => {
    const response = await fetch(
      `https://device-serial-number-check-api.calibrite.com/getDeviceState?serialNumber=${sn}`
    );
    const data = await response.json();

    const devices = data["search"];

    if (Array.isArray(devices) && devices.length > 0) {
      const licenced = devices.some(
        (element) =>
          element["serialNumber"] === sn &&
          (element["licenced"] === 1 || element["licenced"] === true)
      );

      if (licenced) {
        setLicenced(true);
      } else {
        setLicenced(false);
      }
    } else {
      setLicenced(false);
    }

    //maybe parse this as obj
    // return devices;
  };

  return (
    <>
      {licenced ? (
        <Flex
          width={"100%"}
          justifyContent={"center"}
          marginTop="20px"
          color={color.lightWhite}
        >
          <Flex
            width={"60%"}
            maxWidth="700px"
            // justifyContent={"space-between"}
            margin="auto"
            flexWrap={"wrap"}
          >
            <Box width="100%">
              <Image src={logo} alt="logo" />
            </Box>

            <Flex justifyContent={"center"} width="100%" marginTop="-90px">
              <Text fontSize={"lg"} textAlign={"center"}>
                {t(
                  "miscNonHtmlText.This Device is already licensed to use Calibrite PROFILER"
                )}
              </Text>
            </Flex>
            <Flex justifyContent={"center"} width="100%" marginTop="-40px">
              <Text fontSize={"lg"} textAlign={"center"}>
                {t(
                  "miscNonHtmlText.Please restart your application to activate"
                )}
              </Text>
            </Flex>
            {/* {error && (
          <Flex width={"100%"} justifyContent="center">
            <Text>Serial Number: {serial}</Text>
          </Flex>
        )} */}
          </Flex>
        </Flex>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            marginTop="20px"
            color={color.lightWhite}
          >
            {!upgrade ? (
              <Upgrade setUpgrade={setUpgrade} />
            ) : (
              <FormControl>
                <Flex
                  width={"40%"}
                  maxWidth="700px"
                  justifyContent={"space-between"}
                  margin="auto"
                  flexWrap={"wrap"}
                >
                  <Heading size={"md"} width="100%" marginBottom={"10px"}>
                    {t("heading.Calibrite Software Upgrade")}
                  </Heading>
                  <Box width={"100%"}>
                    <hr className="dashed" />
                  </Box>

                  <Box width={"45%"} marginBottom="10px">
                    <FormLabel htmlFor="product">
                      *{t("label.Product")}
                      {errors.product && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => (
                        <Input {...field} disabled={true} />
                      )}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="10px">
                    <FormLabel htmlFor="operating-system">
                      *{t("label.Operating System")}{" "}
                      {errors.operating && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>
                    <Controller
                      name="operating"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => (
                        <Input {...field} disabled={true} />
                      )}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="10px">
                    <FormLabel htmlFor="software">
                      {t("label.Software Version")}{" "}
                      {errors.software && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>
                    <Controller
                      name="software"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => (
                        <Input {...field} disabled={true} />
                      )}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="serial">
                      *{t("label.Serial Number")}{" "}
                      {errors.serialNum && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="serialNum"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className={"disabled-input"}
                          disabled={true}
                        />
                      )}
                    />
                  </Box>
                  <Heading size={"md"} width="100%" marginBottom={"10px"}>
                    {t("heading.Contact Details")}
                  </Heading>
                  <Box width={"100%"}>
                    <hr className="dashed" />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="email">
                      *{t("label.Email")}{" "}
                      {errors.emailAdd && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="emailAdd"
                      control={control}
                      rules={{ required: true, pattern: /^\S+@\S+$/i }}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="company">
                      {t("label.Company Name")}{" "}
                      {errors.companyName && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="companyName"
                      control={control}
                      rules={{ required: false, maxLength: 80 }}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="first">
                      *{t("label.First Name")}{" "}
                      {errors.firstName && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="last">
                      *{t("label.Last Name")}{" "}
                      {errors.lastName && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: true, maxLength: 80 }}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="20px">
                    <FormLabel htmlFor="phone">
                      {t("label.Phone Number")}{" "}
                      {errors.phoneNum && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Controller
                      name="phoneNum"
                      control={control}
                      // rules={{ required: false, minLength: 6, maxLength: 15 }}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Box>
                  <Box width={"45%"} marginBottom="10px">
                    <FormLabel htmlFor="country">
                      *{t("label.Country")}{" "}
                      {errors.country && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <Select
                      // placeholder="Select one"
                      {...register("country", { required: true })}
                    >
                      {countryList
                        .sort((a, b) => (a[language] > b[language] ? 1 : -1))
                        .map((country) => {
                          return (
                            <option key={country.alpha2} value={country.alpha2}>
                              {country[language] || country["en"]}
                            </option>
                          );
                        })}
                    </Select>
                  </Box>
                  <Flex width={"80%"}>
                    <FormLabel htmlFor="support">
                      {t(
                        "miscNonHtmlText.Would you like us to send you relevant product support and user tips?"
                      )}{" "}
                      {errors.support && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>
                    <Box marginTop={6}>
                      <input type="checkbox" {...register("support")} />
                    </Box>
                  </Flex>
                  <Flex width={"80%"} marginTop={3}>
                    <FormLabel htmlFor="terms">
                      {t("miscNonHtmlText.I agree to the")}{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href="https://calibrite.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("miscNonHtmlText.terms and conditions")}
                      </a>
                      .
                      {errors.checkbox && (
                        <span className="form-error">
                          {t("label.This field is required")}
                        </span>
                      )}
                    </FormLabel>

                    <input
                      type="checkbox"
                      {...register("terms")}
                      onClick={() => setTerms(!terms)}
                    />
                  </Flex>
                  <Flex width={"100%"} marginBottom={"20px"} marginTop={3}>
                    <Text>
                      {t(
                        "miscNonHtmlText.For information about our privacy practices split"
                      )}{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href="https://calibrite.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("miscNonHtmlText.Privacy Policy")}
                      </a>
                    </Text>
                  </Flex>

                  <Flex justifyContent={"flex-end"} width={"100%"}>
                    <Button
                      type="submit"
                      colorScheme={"blue"}
                      disabled={!terms}
                    >
                      {t("button.Submit")}
                    </Button>
                  </Flex>
                </Flex>
              </FormControl>
            )}
          </Flex>
        </form>
      )}
    </>
  );
};

export default Form;
