import { ChakraProvider } from "@chakra-ui/react";
import Main from "./Main";
import "./styles/style.css";
import theme from "./theme";

const App = () => (
  <>
    <ChakraProvider theme={theme}>
      <Main />
    </ChakraProvider>
  </>
);
export default App;
