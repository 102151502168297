import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { color } from "./constants/color";
import logo from "./assets/Calibrite-colour-nocircle.svg";
import { useTranslation } from "react-i18next";

const Confirmation = () => {
  //call database
  // stay loading while licenced is false
  const count = useRef(0);
  const intervalId = useRef();
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serial, setSerial] = useState("");
  const { t, i18n } = useTranslation();

  const clearPoll = () => {
    console.log("clearing poll");
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    const sn = localStorage.getItem("Calibrite-sn");
    setSerial(sn);
    // const sn = 1234;
    const lng = localStorage.getItem("Calibrite-lang");

    const fakeDb = [
      { sn: "1234", licenced: true },
      { sn: "4321", licenced: false },
    ];

    const licenceApi = async (sno) => {
      const response = await fetch(
        `https://device-serial-number-check-api.calibrite.com/getDeviceState?serialNumber=${sno}`
      );
      const data = await response.json();

      const devices = data["search"];

      //maybe parse this as obj
      return devices;
    };

    const fetchFromDb = async (sn) => {
      try {
        console.log(count.current);
        console.log("fetching");
        setError(false);
        if (confirmed || count.current > 20) {
          clearPoll();
          setLoading(false);
        } else {
          const devices = await licenceApi(sn);

          // add type of string
          if (Array.isArray(devices) && devices.length > 0) {
            const licenced = devices.some(
              (element) =>
                element["serialNumber"] === sn &&
                (element["licenced"] === 1 || element["licenced"] === true)
            );

            if (licenced) {
              setConfirmed(licenced);
            }

            count.current = count.current + 1;
          } else {
            // set error sn not found
            setError(t("miscNonHtmlText.Serial number not found"));
            setLoading(false);
          }
        }
      } catch (e) {
        console.log(e);
        setError(
          `${t(
            "miscNonHtmlText.There was an error please contact support Serial Num"
          )}: ${serial}`
        );
        clearPoll();
        setLoading(false);
      }
    };

    if (!confirmed) {
      const id = setInterval(() => fetchFromDb(sn), 500);
      intervalId.current = id;
    }

    confirmed && setLoading(false);

    return () => {
      clearPoll();
    };
  }, [confirmed]);

  return (
    <Flex
      width={"100%"}
      justifyContent={"center"}
      marginTop="20px"
      color={color.lightWhite}
    >
      <Flex
        width={"60%"}
        maxWidth="700px"
        // justifyContent={"space-between"}
        margin="auto"
        flexWrap={"wrap"}
      >
        <Box className={loading && "blink"} width="100%">
          <Image src={logo} alt="logo" />
        </Box>

        <Flex justifyContent={"center"} width="100%" marginTop="-90px">
          {confirmed && (
            <Text fontSize={"lg"}>
              {t(
                "miscNonHtmlText.Upgrade Registration Complete - Please relaunch Calibrite PROFILER"
              )}
            </Text>
          )}
          {loading && (
            <Text fontSize={"lg"}>
              {t("miscNonHtmlText.Awaiting Confirmation of upgrade")}.....
            </Text>
          )}
          {error && <Text fontSize={"lg"}>{error}</Text>}
          {count.current > 20 && !confirmed && (
            <Box textAlign={"center"}>
              <Text fontSize={"lg"}>
                {t(
                  "miscNonHtmlText.There was an issue confirming registration of serial number"
                )}{" "}
                {serial} -{" "}
                {t(
                  "miscNonHtmlText.Please relaunch Calibrite PROFILER to confirm and contact support if required"
                )}
                .
              </Text>
            </Box>
          )}
        </Flex>
        {/* {error && (
          <Flex width={"100%"} justifyContent="center">
            <Text>Serial Number: {serial}</Text>
          </Flex>
        )} */}
      </Flex>
    </Flex>
  );
};

export default Confirmation;
