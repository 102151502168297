import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: "en", // default language
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["localStorage", "path", "htmlTag", "cookie"],
      caches: ["localStorage", "cookie"], // cache user language on
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === "number") {
          console.log(
            "Formatted num",
            new Intl.NumberFormat(lng).format(value)
          );
          return new Intl.NumberFormat(lng).format(value);
        }
        if (format === "date") {
          return new Intl.DateTimeFormat(lng).format(value);
        }
        if (format === "currency") {
          return new Intl.NumberFormat(lng, {
            style: "currency",
            currency: "USD",
          }).format(value);
        }
      },
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
