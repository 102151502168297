import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "./assets/Banner.png";
import Banner_de from "./assets/Banner_de.png";
import Banner_es from "./assets/Banner_es.png";
import Banner_fr from "./assets/Banner_fr.png";
import Banner_it from "./assets/Banner_it.png";
import Banner_pl from "./assets/Banner_pl.png";

const Upgrade = ({ setUpgrade }) => {
  const redirect = (url) => {
    window.location.href = url;
  };

  const { t, i18n } = useTranslation();

  const getBanner = () => {
    const lang = i18n.language;

    if (lang === "es") {
      return Banner_es;
    } else if (lang === "de") {
      return Banner_de;
    } else if (lang === "fr") {
      return Banner_fr;
    } else if (lang === "it") {
      return Banner_it;
    } else if (lang === "pl") {
      return Banner_pl;
    } else {
      return Banner;
    }
  };

  return (
    <Flex
      width={"40%"}
      maxWidth="700px"
      minWidth="700px"
      justifyContent={"space-between"}
      margin="auto"
      flexWrap={"wrap"}
      border={"2px solid white"}
      paddingBottom={5}
    >
      <Box width="100%" color="black">
        <Image src={getBanner()} />
        <div className="bar_image small" />
      </Box>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        paddingLeft={10}
        paddingRight={10}
      >
        <Text fontSize="xl" fontWeight={"bold"} marginBottom={3} marginTop={3}>
          {t(
            "miscNonHtmlText.Upgrade to use your X-Rite device with Calibrite PROFILER to give your X-Rite device the same functionality as the following Calibrite devices"
          )}{" "}
          :
        </Text>
        <Flex direction={"column"} marginBottom={3}>
          <Text>
            {t(
              "miscNonHtmlText.X-Rite i1 Display Plus upgrades to Calibrite ColorChecker Display Plus"
            )}
          </Text>
          <Text>
            {t(
              "miscNonHtmlText.X-Rite i1 Display Pro upgrades to Calibrite ColorChecker Display Pro"
            )}
          </Text>
          <Text>
            {t(
              "miscNonHtmlText.X-Rite i1 Display Studio upgrades to Calibrite ColorChecker Display"
            )}
          </Text>

          <Text>
            {t(
              "miscNonHtmlText.X-Rite ColorMunki Display upgrades to Calibrite ColorChecker Display"
            )}
          </Text>
        </Flex>
        <Text marginBottom={3}>
          {t(
            "miscNonHtmlText.Calibrite PROFILER software offers these great features"
          )}
          :
        </Text>
        <UnorderedList marginBottom={3}>
          <ListItem>
            {t("miscNonHtmlText.Intuitive feature rich and simple to use")}
          </ListItem>
          <ListItem>
            {t(
              "miscNonHtmlText.Newly designed interface for all levels of users"
            )}
          </ListItem>
          <ListItem>
            {t(
              "miscNonHtmlText.Built in presets for photographers videographers and contentmcreators"
            )}
          </ListItem>
          <ListItem>
            {t(
              "miscNonHtmlText.Fully customisable features for advanced users"
            )}
          </ListItem>
          <ListItem>
            {t(
              "miscNonHtmlText.Easily manage the presets and profiles you have created with the Profile and Preset Manager"
            )}
          </ListItem>
          <ListItem>
            {t(
              "miscNonHtmlText.Advanced utilities to ensure you get the best out of your monitor"
            )}
          </ListItem>
        </UnorderedList>
        <Text fontSize={"x-large"}>
          {t("miscNonHtmlText.Upgrade today for only")} $39.99 / £34.99 / 39,99€
          / 179 zł*
        </Text>
        <Text>*{t("miscNonHtmlText.or equivalent local currency")}</Text>
      </Flex>

      {/* <Text color="white">This is the landing page </Text> */}
      <Flex width="100%" paddingTop="10px" justifyContent={"center"}>
        <Button
          width={"400px"}
          colorScheme={"green"}
          onClick={() => setUpgrade(true)}
        >
          Upgrade
        </Button>
      </Flex>
    </Flex>
  );
};

export default Upgrade;
