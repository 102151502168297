/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import { color } from "./constants/color";
import logo from "./assets/Calibritelogo-onblack.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    let lng = params.get("lng");

    if (lng !== null) {
      console.log(lng);
      i18n.changeLanguage(lng);
      localStorage.setItem("i18nextLng", lng);
      setLanguage(lng);
    } else {
      let lang = localStorage.getItem("i18nextLng");
      if (lang) {
        i18n.changeLanguage(lang);
        setLanguage(lang);
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem("i18nextLng", "en");
      }
    }
  }, []);

  return (
    <>
      <Box padding={5} backgroundColor={color.grey} height={"90px"}>
        <Flex justifyContent="space-between">
          <Flex>
            <Image width={120} src={logo} />
          </Flex>

          {/* <Box marginTop={8} className="circle_lang">
            {language && language.toUpperCase()}
          </Box> */}
        </Flex>
        <br />
      </Box>

      <div className="bar_image" />
    </>
  );
};

export default Header;
