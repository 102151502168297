import { Box, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Form from "./Form";
import Header from "./Header";
import {
  Route,
  Routes,
  HashRouter,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Confirmation from "./Confirmation";

// The results dot used by the results page for multiple results
const Main = () => {
  //   colorIn.replace(/['"] +/g, '');
  const [language, setLanguage] = useState("en");

  //TRANSLATE LANDING PAGE AND CONFIRMATION
  //AWAITING LANDING BANNER

  // ADD FURTHER QUERIES TO STRIPE REDIRECT AND TEST IF POPULATING
  // Deploy, add stripe route and test
  // it currently says ColorMunki Display, this should say X-Rite ColorMunki Display
  // add external route

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            exact
            path="/"
            element={<Form language={language} setLanguage={setLanguage} />}
          />
          <Route exact path="/confirmation" element={<Confirmation />} />
          <Route path="" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Main;
